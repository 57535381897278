.centralized-container{
    background: linear-gradient(rgba(23, 128, 231, 0.30), rgba(23, 128, 231, 0.30)), url("../../../../public/img/pivotel_slider4.svg");
    background-size: cover;
    background-position: center;
    width: 100%;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.centralized-cardContent{
    padding-top: 15px;
    margin-bottom: -45px ;
    margin-right: -5px;
    margin-left: -5px;
}

.centralized-corporateLogo{
   text-align: center;
   padding-bottom: 30px ;
}

.centralized-main-heading{
    margin-bottom: 10px;
}

.centralized-poweredByLogo{
    position: absolute;
    margin-top: 600px;
}