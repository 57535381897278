.form-group {
	text-align: initial;
}

.password-check-box {
	margin-left: 37%;
}

.password-check {
	z-index: 9999;
	position: absolute;
	background-color: #fff;
	color: #000000;
	width: 220px;
	border-radius: 6px;
	margin-top: 10px;
	padding: 20px;
	box-shadow: 0 0 6px 0 rgba(42, 45, 60, 0.4);
}
