#bootstrap-overrides button.onship-btn {
	background-color: #0095f2;
	text-transform: none !important;
	font-family: "Figtree-Regular";
}
#bootstrap-overrides a.onship-txt {
	color: #0095f2 !important;
	text-transform: none !important;
	font-family: "Figtree-Regular";
}
#bootstrap-overrides a.onship-link {
	color: #0095f2 !important;
	border-color: #0095f2;
	font-family: "Figtree-Regular";
}
