/*
main templates layouts
 */
.App-wrapper {
	height: 100vh;
	/* If you need to support browser without CSS var support (<= IE11) */
	height: calc(100vh - var(--vh-offset, 0px));
	/* enable vh fix */
	display: flex;
	background-color: #fff;
}

.App-main {
	flex: 1;
	display: flex;
	width: 100%;
}

.App-navbar {
	position: relative;
	display: block !important;
	z-index: 750;
	opacity: 1;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	width: 200px;
}
.App-navbar > * {
	width: inherit;
	margin: 0;
	overflow: hidden;
	height: 100%;
}

.App-content {
	flex: 1;
	overflow: hidden;
}

@media (max-width: 767px) {
	.App-navbar {
		display: none !important;
		opacity: 0;
		position: relative;
		z-index: 750;
		opacity: 1;
		transition: all 0.3s;
		-webkit-transition: all 0.3s;
		width: 200px;
	}
	.App-navbar > * {
		width: inherit;
		margin: 0;
		overflow: hidden;
		height: 100%;
	}
}
